<template>
  <Toast
    :is-open="true"
    :text="notification.message"
    :action-button-text="notification.action?.label"
    :action-click="onActionClick"
    :on-close-request="onClose"
    :aria-label-close-btn="translateKey('components.appToast.dismiss')"
  />
</template>

<script lang="ts" setup>
  import { useI18n } from '@/hooks/use-i18n.hook';
  import { useGlobalNotificationsStore } from '@/store/global-notifications/global-notifications.store';
  import Toast from '@ingka/toast-vue';
  import type { ToastProps } from './AppToast.types';

  const props = defineProps<ToastProps>();

  const { translateKey } = useI18n();
  const globalNotificationsStore = useGlobalNotificationsStore();

  const onActionClick = () => {
    props.notification.action?.callback();
    globalNotificationsStore.remove(props.notification.id);
  };

  const onClose = () => {
    globalNotificationsStore.remove(props.notification.id);
  };
</script>

<style lang="scss" scoped></style>
