import * as API from '@/api/api';
import { EntityMutationType } from '@/types/entity-mutation-type.enum';
import type { UserViewNotificationViewModel } from '@/types/view-models/user-view-notification-view-model.interface';
import { defineStore } from 'pinia';
import { useWebSocketsStore } from '../web-sockets/web-sockets.store';
import type {
  UserViewNotificationStoreActions,
  UserViewNotificationStoreGetters,
  UserViewNotificationStoreState,
} from './user-view-notification-store.types';

export const useUserViewNotificationStore = defineStore<
  'userViewNotification',
  UserViewNotificationStoreState,
  UserViewNotificationStoreGetters,
  UserViewNotificationStoreActions
>('userViewNotification', {
  state: () => ({
    userViewNotificationsIds: new Set(),
    userViewNotificationsLoading: false,
  }),
  getters: {
    userViewNotifications(): UserViewNotificationViewModel[] {
      const webSocketsStore = useWebSocketsStore();

      return [...this.userViewNotificationsIds]
        .filter((id) => webSocketsStore.userViewNotificationById[id])
        .map((id) => webSocketsStore.userViewNotificationById[id]!);
    },
  },
  actions: {
    processEntityMutation(userId, userEmail, entityMutation): void {
      switch (entityMutation.mutation_type) {
        case EntityMutationType.USER_VIEW_NOTIFICATION_CREATED:
          this.userViewNotificationsIds.add(entityMutation.entity.id);
          break;

        case EntityMutationType.USER_VIEW_NOTIFICATION_DELETED:
          this.userViewNotificationsIds.delete(entityMutation.entity_id);
          break;
      }
    },

    async fetchUserViewNotifications(): Promise<void> {
      const webSocketsStore = useWebSocketsStore();

      this.userViewNotificationsLoading = true;

      const userViewNotifications = await API.getUserViewNotifications();

      this.userViewNotificationsIds = new Set(userViewNotifications.map(({ id }) => id));
      webSocketsStore.updateUserViewNotificationById(userViewNotifications);

      this.userViewNotificationsLoading = false;
    },
  },
});
