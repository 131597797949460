<template>
  <div :class="bem()">
    <AppBouncingBall />
  </div>
</template>

<script lang="ts" setup>
  import AppBouncingBall from '@/components/AppBouncingBall/AppBouncingBall.vue';
  import { useBEM } from '@/hooks/use-bem.hook';

  const bem = useBEM('AppLoadingOverlay');
</script>

<style lang="scss" scoped>
  .AppLoadingOverlay {
    position: fixed;
    inset: 0;
    z-index: 10000;
    background: rgb(var(--colour-static-white));
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
  }
</style>
