import type { IdTokenStoreActions, IdTokenStoreState } from '@/store/id-token/id-token-store.types';
import { defineStore } from 'pinia';

export const useIDTokenStore = defineStore<'idToken', IdTokenStoreState, Record<string, never>, IdTokenStoreActions>(
  'idToken',
  {
    state: () => ({
      token: undefined,
    }),

    actions: {
      setToken(payload: string): void {
        this.token = payload;
      },

      clearToken(): void {
        delete this.token;
      },
    },
  },
);
