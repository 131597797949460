export enum EntityMutationType {
  USER_VIEW_CREATED = 'user_view_created',
  USER_VIEW_UPDATED = 'user_view_updated',
  USER_VIEW_CHANGE_HISTORY_CREATED = 'user_view_change_history_created',
  USER_VIEW_COMMENT_CREATED = 'user_view_comment_created',
  USER_VIEW_COMMENT_UPDATED = 'user_view_comment_updated',
  USER_VIEW_COMMENT_DELETED = 'user_view_comment_deleted',
  USER_VIEW_NOTIFICATION_CREATED = 'user_view_notification_created',
  USER_VIEW_NOTIFICATION_DELETED = 'user_view_notification_deleted',
  USER_SESSION_CREATED = 'user_session_created',
  USER_SESSION_UPDATED = 'user_session_updated',
  USER_SESSION_DELETED = 'user_session_deleted',
}
