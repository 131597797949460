export enum RangePageTabView {
  RANGE_GRAPH,
  OVERVIEW,
  TABLE,
}

export enum MyProjectsPageTabView {
  RECENT_PROJECTS,
  ALL_PROJECTS,
}
