<template>
  <AppNotificationsContainer />

  <AppLoadingOverlay v-if="loading" />
  <router-view v-else></router-view>
</template>

<script lang="ts" setup>
  import AppNotificationsContainer from '@/components/AppNotificationsContainer/AppNotificationsContainer.vue';
  import { useIDTokenStore } from '@/store/id-token/id-token.store';
  import { debounce } from 'lodash';
  import { computed, onMounted, ref, watch } from 'vue';
  import { useRoute, useRouter } from 'vue-router';
  import AppLoadingOverlay from './components/AppLoadingOverlay/AppLoadingOverlay.vue';
  import { HEARTBEAT_DEBOUNCE_MS } from './constants';
  import { useAuthentication } from './hooks/use-authentication.hook';
  import { useUserSettingsStore } from './store/user-settings/user-settings.store';
  import { useUserViewChangeHistoryStore } from './store/user-view/user-view-change-history.store';
  import { useUserViewCommentStore } from './store/user-view/user-view-comment.store';
  import { useUserViewNotificationStore } from './store/user-view/user-view-notification.store';
  import { useUserViewStore } from './store/user-view/user-view.store';
  import { useWebSocketsStore } from './store/web-sockets/web-sockets.store';

  const { idTokenClaims, user } = useAuthentication();
  const idTokenStore = useIDTokenStore();
  const route = useRoute();
  const router = useRouter();
  const webSocketsStore = useWebSocketsStore();
  const userViewStore = useUserViewStore();
  const userViewCommentStore = useUserViewCommentStore();
  const userViewNotificationStore = useUserViewNotificationStore();
  const userViewChangeHistoryStore = useUserViewChangeHistoryStore();
  const userSettingsStore = useUserSettingsStore();

  const token = computed<string | undefined>(() => {
    return idTokenClaims.value?.__raw;
  });

  const loading = ref<boolean>(true);

  const trackUserActivity = debounce(() => {
    webSocketsStore.sendHeartbeat();
  }, HEARTBEAT_DEBOUNCE_MS);

  // Attach event listeners for user activity
  const attachActivityListeners = () => {
    window.addEventListener('mousemove', trackUserActivity);
    window.addEventListener('keypress', trackUserActivity);
    window.addEventListener('click', trackUserActivity);
  };

  onMounted(async () => {
    await router.isReady();

    if (!token.value) {
      loading.value = false;
      return;
    }

    const userId = user.value!.sub!;
    const userEmail = user.value!.email!;

    webSocketsStore.initWebSocketChannels(userId, userEmail, token.value, [
      userViewStore,
      userViewCommentStore,
      userViewNotificationStore,
      userViewChangeHistoryStore,
    ]);

    attachActivityListeners(); // Start tracking user activity

    await userSettingsStore.fetchSettings();

    loading.value = false;
  });

  watch(idTokenClaims, () => {
    const idToken = idTokenClaims.value?.__raw;

    if (idToken) {
      idTokenStore.setToken(idToken);
    }
  });

  watch(route, () => {
    // Only start updating last path once initial loading has completed
    if (loading.value) {
      return;
    }

    webSocketsStore.updateLastRoutePath(route.path);
  });
</script>
