import { useAuthentication } from '@/hooks/use-authentication.hook';
import { useRedirect } from '@/hooks/use-redirect.hook';
import { useRetailUnitCodesStore } from '@/store/retail-unit-codes/retail-unit-codes.store';
import { QueryParamType } from '@/types/query-param-type.enum';
import { authGuard } from '@auth0/auth0-vue';
import type { NavigationGuard } from 'vue-router';
import { RoutePath } from './routes';

export const isAuthenticatedGuard: NavigationGuard = async (to, from, next) => {
  const { isLoading, awaitLoading, isAuthenticated } = useAuthentication();
  const { setRedirect, getRedirect, clearRedirect } = useRedirect();

  if (isLoading.value) {
    await awaitLoading();
  }

  if (isAuthenticated.value) {
    const redirect = getRedirect();

    if (redirect) {
      clearRedirect();
      return next(redirect);
    }

    return next();
  }

  setRedirect({ path: to.path, query: to.query });

  authGuard(to);
};

export const isUnauthenticatedGuard: NavigationGuard = async (to, from, next) => {
  const { isLoading, awaitLoading, isAuthenticated } = useAuthentication();

  if (isLoading.value) {
    await awaitLoading();
  }

  if (isAuthenticated.value) {
    return next({ path: RoutePath.MARKET_SELECTION });
  }

  next();
};

export const retailUnitCodeGuard: NavigationGuard = async (to, from, next): Promise<void> => {
  const retailUnitCodesStore = useRetailUnitCodesStore();

  await retailUnitCodesStore.fetchRetailUnitCodes();

  const retailUnitCode = to.query[QueryParamType.RETAIL_UNIT_CODE] as string | undefined;

  if (retailUnitCode && retailUnitCodesStore.retailUnitCodes.includes(retailUnitCode)) {
    return next();
  }

  // If there is access to only one market, set it as default
  if (retailUnitCodesStore.retailUnitCodes.length === 1) {
    return next({
      path: RoutePath.RANGE,
      query: { [QueryParamType.RETAIL_UNIT_CODE]: retailUnitCodesStore.retailUnitCodes[0] },
    });
  }

  return next({ path: RoutePath.MARKET_SELECTION });
};
