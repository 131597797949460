import * as API from '@/api/api';
import { EntityMutationType } from '@/types/entity-mutation-type.enum';
import type { UserViewComment } from '@/types/models/user-view-comment.interface';
import { defineStore } from 'pinia';
import { useWebSocketsStore } from '../web-sockets/web-sockets.store';
import type {
  UserViewCommentStoreActions,
  UserViewCommentStoreGetters,
  UserViewCommentStoreState,
} from './user-view-comment-store.types';

export const useUserViewCommentStore = defineStore<
  'userViewComment',
  UserViewCommentStoreState,
  UserViewCommentStoreGetters,
  UserViewCommentStoreActions
>('userViewComment', {
  state: () => ({
    userViewCommentsIdsByViewId: {},
    userViewCommentIdsLoadingByViewId: {},
    loadingByUserViewCommentId: {},
  }),
  getters: {
    userViewCommentsByViewId(): Record<string, UserViewComment[]> {
      const webSocketsStore = useWebSocketsStore();

      return Object.fromEntries(
        Object.entries(this.userViewCommentsIdsByViewId).map(([viewId, userViewCommentsIds]) => [
          viewId,
          [...userViewCommentsIds]
            .filter((id) => webSocketsStore.userViewCommentById[id])
            .map((id) => webSocketsStore.userViewCommentById[id]!),
        ]),
      );
    },
  },
  actions: {
    processEntityMutation(userId, userEmail, entityMutation): void {
      switch (entityMutation.mutation_type) {
        case EntityMutationType.USER_VIEW_COMMENT_CREATED:
          this.userViewCommentsIdsByViewId[entityMutation.entity.view_id].add(entityMutation.entity.id);
          break;

        case EntityMutationType.USER_VIEW_COMMENT_DELETED:
          this.userViewCommentsIdsByViewId[entityMutation.view_id].delete(entityMutation.entity_id);
          break;
      }
    },

    async createUserViewComment({ viewId, parentId, sectionId, comment }): Promise<void> {
      const webSocketsStore = useWebSocketsStore();

      const userViewComment = await API.createUserViewComment({
        view_id: viewId,
        parent_id: parentId,
        section_id: sectionId,
        comment,
      });

      webSocketsStore.userViewCommentById[userViewComment.id] = userViewComment;
      this.userViewCommentsIdsByViewId[viewId].add(userViewComment.id);
    },

    async updateUserViewComment({ commentId, comment, reactions }): Promise<void> {
      const webSocketsStore = useWebSocketsStore();

      this.loadingByUserViewCommentId[commentId] = true;

      const userViewComment = await API.updateUserViewComment({
        comment_id: commentId,
        comment,
        reactions,
      });

      webSocketsStore.userViewCommentById[userViewComment.id] = userViewComment;

      this.loadingByUserViewCommentId[commentId] = false;
    },

    async deleteUserViewComment(viewId, commentId): Promise<void> {
      const webSocketsStore = useWebSocketsStore();

      this.loadingByUserViewCommentId[commentId] = true;

      await API.deleteUserViewComment(viewId, commentId);

      this.userViewCommentsIdsByViewId[viewId].delete(commentId);
      delete webSocketsStore.userViewCommentById[commentId];

      this.loadingByUserViewCommentId[commentId] = false;
    },

    async fetchUserViewComments(viewId): Promise<void> {
      const webSocketsStore = useWebSocketsStore();

      this.userViewCommentIdsLoadingByViewId[viewId] = true;

      const userViewComments = await API.getUserViewComments(viewId);

      this.userViewCommentsIdsByViewId[viewId] = new Set(userViewComments.map(({ id }) => id));
      webSocketsStore.updateUserViewCommentById(userViewComments);

      this.userViewCommentIdsLoadingByViewId[viewId] = false;
    },
  },
});
