import { getI18n } from '@/api/api';
import { Language } from '@/types/language.enum';
import { defineStore } from 'pinia';
import type { I18nStoreActions, I18nStoreState } from './i18n-store.type';

export const useI18nStore = defineStore<'i18nStore', I18nStoreState, Record<string, never>, I18nStoreActions>(
  'i18nStore',
  {
    state: () => ({
      language: Language.EN,
      translationsByLanguage: {
        [Language.EN]: null,
      },
      translationsLoadingByLanguage: {
        [Language.EN]: false,
      },
    }),
    actions: {
      async setLanguage(language): Promise<void> {
        this.language = language;

        if (this.translationsByLanguage[language]) {
          return;
        }

        this.translationsLoadingByLanguage[language] = true;

        const translations = await getI18n(language);

        if (translations) {
          this.translationsByLanguage[language] = translations;
        }

        this.translationsLoadingByLanguage[language] = false;
      },
    },
  },
);
