import { RoutePath } from '@/router/routes';
import { User, useAuth0, type IdToken } from '@auth0/auth0-vue';
import type { Ref } from 'vue';

const LOADING_CHECK_INTERVAL = 50;
const LOADING_TIMEOUT_INTERVAL = 5000;
const LOADING_TIMED_OUT_MESSAGE = 'Authentication loading timed out!';

interface Authentication {
  login: () => Promise<void>;
  isLoading: Ref<boolean>;
  awaitLoading: () => Promise<void>;
  isAuthenticated: Ref<boolean>;
  user: Ref<User | undefined>;
  idTokenClaims: Ref<IdToken | undefined>;
  getAccessTokenSilently: () => Promise<string>;
  logout: () => Promise<void>;
}

export const useAuthentication = (): Authentication => {
  const {
    loginWithRedirect: login,
    isLoading,
    isAuthenticated,
    user,
    idTokenClaims,
    getAccessTokenSilently,
    logout,
  } = useAuth0();

  // Wait for the loading state to finalize
  const awaitLoading = (): Promise<void> => {
    return new Promise<void>((resolve, reject) => {
      const interval = setInterval(() => {
        if (!isLoading.value) {
          clearInterval(interval);
          clearTimeout(timeout);
          resolve();
        }
      }, LOADING_CHECK_INTERVAL);

      // Reject if it takes too long
      const timeout = setTimeout(() => {
        clearInterval(interval);
        reject(new Error(LOADING_TIMED_OUT_MESSAGE));
        console.error(new Error(LOADING_TIMED_OUT_MESSAGE));

        login();
      }, LOADING_TIMEOUT_INTERVAL);
    });
  };

  return {
    login,
    isLoading,
    awaitLoading,
    isAuthenticated,
    user,
    idTokenClaims,
    getAccessTokenSilently,
    logout: (): Promise<void> => {
      return logout({
        logoutParams: {
          returnTo: `${window.location.origin}${RoutePath.SIGNED_OUT}`,
        },
      });
    },
  };
};
