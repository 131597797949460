import { useI18nStore } from '@/store/i18n/i18n.store';

export const useI18n = () => {
  const i18nStore = useI18nStore();

  const translateKey = (
    translationKey: string,
    replacements: Record<string, string | number | null | undefined> = {},
  ): string => {
    const { language, translationsByLanguage } = i18nStore;
    const translations = translationsByLanguage[language];

    let translation = translations?.[translationKey];

    if (!translation) {
      console.warn(`[i18n] ${translationKey} is missing`);

      // Fallback to the key itself if translation is missing
      return translationKey;
    }

    Object.keys(replacements).forEach((key) => {
      const replacement = replacements[key];

      if (replacement === null || replacement === undefined) {
        return;
      }

      translation = translation!.replace(new RegExp(`{{${key}}}`, 'g'), replacement.toString());
    });

    return translation;
  };

  return {
    translateKey,
  };
};
