import type { GlobalNotification } from '@/types/global-notification.interface';
import { uniqueId } from 'lodash';
import { defineStore } from 'pinia';
import type { GlobalNotificationsActions, GlobalNotificationsState } from './global-notifications-store.types';

export const useGlobalNotificationsStore = defineStore<
  'GlobalNotifications',
  GlobalNotificationsState,
  Record<string, never>,
  GlobalNotificationsActions
>('GlobalNotifications', {
  state: () => ({
    notifications: [],
  }),

  actions: {
    add(data: Omit<GlobalNotification, 'id'>) {
      this.notifications.push({ id: uniqueId(), message: data.message, action: data.action });
    },

    remove(id) {
      const index = this.notifications.findIndex((n) => n.id === id);

      setTimeout(() => {
        if (index !== -1) {
          this.notifications.splice(index, 1);
        }
      }, 200);
    },
  },
});
