<template>
  <div :class="bem()">
    <template v-if="!dataNotAvailable">
      <div :class="bem('ball-container')">
        <div :class="bem('ball')"></div>
      </div>

      <p :class="bem('loading-text')">{{ translateKey('components.appBouncingBall.loading') }}</p>
    </template>

    <p v-else :class="bem('data-not-available-text')">
      {{ translateKey('components.appBouncingBall.dataNotAvailable') }}
    </p>
  </div>
</template>

<script lang="ts" setup>
  import { useBEM } from '@/hooks/use-bem.hook';
  import { useI18n } from '@/hooks/use-i18n.hook';
  import { onMounted, ref } from 'vue';
  import { LOADING_DURATION_MS } from './AppBouncingBall.constants';

  const bem = useBEM('BouncingBall');
  const { translateKey } = useI18n();

  const dataNotAvailable = ref(false);

  onMounted(() => {
    setTimeout(() => {
      dataNotAvailable.value = true;
    }, LOADING_DURATION_MS);
  });
</script>

<style lang="scss" scoped>
  .BouncingBall {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .BouncingBall__ball-container {
      height: 2.5rem;
      position: relative;
      display: flex;
      justify-content: center;
      margin-bottom: 1rem;

      @keyframes bounce {
        0% {
          transform: translateY(0);
          animation-timing-function: ease-in;
        }

        50% {
          transform: translateY(30px);
          height: 18px;
          animation-timing-function: ease-out;
        }

        55% {
          transform: translateY(32px);
          height: 14px;
          animation-timing-function: ease-in;
        }

        65% {
          transform: translateY(28px);
          height: 16px;
          animation-timing-function: ease-in;
        }

        95% {
          transform: translateY(0);
          animation-timing-function: ease-in;
        }

        100% {
          transform: translateY(0);
          animation-timing-function: ease-in;
        }
      }

      .BouncingBall__ball {
        width: 16px;
        height: 16px;
        border-radius: 100%;
        background: #0058a3;
        animation: bounce 0.6s;
        animation-iteration-count: infinite;
        position: absolute;
      }
    }

    .BouncingBall__data-not-available-text {
      font-weight: bold;
    }
  }
</style>
