<template>
  <div :class="bem('', [...(routes.range.isActive || routes.range.isChildrenRouteActive ? ['findd-tail'] : [])])">
    <template v-if="globalNotificationsStore.notifications.length">
      <template v-for="notification in globalNotificationsStore.notifications" :key="notification.id">
        <AppToast :notification="notification" />
      </template>
    </template>
  </div>
</template>

<script lang="ts" setup>
  import AppToast from '@/components/AppToast/AppToast.vue';
  import { useBEM } from '@/hooks/use-bem.hook';
  import { useRoutes } from '@/router/routes';
  import { useGlobalNotificationsStore } from '@/store/global-notifications/global-notifications.store';

  const bem = useBEM('AppNotificationsContainer');
  const routes = useRoutes();
  const globalNotificationsStore = useGlobalNotificationsStore();
</script>

<style lang="scss" scoped>
  .AppNotificationsContainer {
    position: fixed;
    right: 0;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: 0.5s;
    z-index: 100000;

    .toast {
      position: unset;
    }

    &:not(.AppNotificationsContainer--tail) {
      top: calc(0% + 4.5rem);
    }

    &.AppNotificationsContainer--tail {
      top: calc(0% + 8rem);
    }
  }
</style>
