import type { Redirect } from '@/types/redirect.interface';

const REDIRECT_KEY = 'redirect';

export const useRedirect = () => {
  const setRedirect = (redirect: Redirect): void => {
    localStorage.setItem(REDIRECT_KEY, JSON.stringify(redirect));
  };

  const getRedirect = (): Redirect | null => {
    const redirectJSON = localStorage.getItem(REDIRECT_KEY);
    return redirectJSON ? JSON.parse(redirectJSON) : null;
  };

  const clearRedirect = (): void => {
    localStorage.removeItem(REDIRECT_KEY);
  };

  return {
    setRedirect,
    getRedirect,
    clearRedirect,
  };
};
