import { type IconType } from '@/components/AppIcons';
import { MenuAboutSections } from '@/components/AppMenuAbout/AppMenuAbout.types';
import { useI18n } from '@/hooks/use-i18n.hook';
import { GroupOrderBy } from '@/types/group-order-by.enum';
import { Grouping } from '@/types/grouping.enum';
import { OrderDirection } from '@/types/order-direction.enum';
import type { QueryParamsValues } from '@/types/query-params-values.interface';
import { MyProjectsPageTabView } from '@/types/tab-views.type';
import { computed } from 'vue';
import { useRoute } from 'vue-router';

export type Routes = {
  signIn: Route;
  marketSelection: Route;
  range: Route;
  projects: Route;
  about: Route;
  notAuthorized: Route;
  loggedOut: Route;
};

export type Route = {
  name: string;
  path: string;
  isActive: boolean;
  icon?: IconType;
  isChildrenRouteActive?: boolean;
  defaultQueryParamsValues?: Partial<QueryParamsValues>;
};

export enum RoutePath {
  SIGN_IN = '/',
  MARKET_SELECTION = '/market-selection',
  RANGE = '/range',
  ABOUT = '/about',
  PROJECTS = '/projects',
  NOT_AUTHORIZED = '/not-authorized',
  SIGNED_OUT = '/logged-out',
}

export const useRoutes = () => {
  const currentRoute = useRoute();
  const { translateKey } = useI18n();

  return computed<Routes>(() => ({
    signIn: {
      name: translateKey('routes.signIn'),
      path: RoutePath.SIGN_IN,
      isActive: currentRoute.matched.some((route) => route.path === RoutePath.SIGN_IN),
    },
    marketSelection: {
      name: translateKey('routes.marketSelection'),
      path: RoutePath.MARKET_SELECTION,
      isActive: currentRoute.matched.some((route) => route.path === RoutePath.MARKET_SELECTION),
    },
    range: {
      name: translateKey('routes.range'),
      path: RoutePath.RANGE,
      icon: 'DataChartIcon',
      isActive: currentRoute.matched.some((route) => route.path === RoutePath.RANGE),
      isChildrenRouteActive: currentRoute.matched.some((route) => route.path === `${RoutePath.RANGE}/:viewId?`),
      defaultQueryParamsValues: {
        fiscalYear: 'FY24',
        grouping: Grouping.HFB,
        groupingPath: [Grouping.HFB],
        groupOrderBy: GroupOrderBy.TOTAL_ARTICLES,
        groupOrderDirection: OrderDirection.DESC,
      },
    },
    projects: {
      name: translateKey('routes.projects'),
      path: RoutePath.PROJECTS,
      icon: 'PersonIcon',
      isActive: currentRoute.matched.some((route) => route.path === RoutePath.PROJECTS),
      isChildrenRouteActive: currentRoute.matched.some((route) => route.path === `${RoutePath.PROJECTS}/:id?`),
      defaultQueryParamsValues: {
        fiscalYear: 'FY24',
        myProjectsPageTabView: MyProjectsPageTabView.RECENT_PROJECTS,
      },
    },
    about: {
      name: translateKey('routes.about'),
      path: `${RoutePath.ABOUT}/${MenuAboutSections.WORK_WITH_KPIS}`,
      icon: 'InformationIcon',
      isActive: currentRoute.matched.some((route) => route.path === RoutePath.ABOUT),
      isChildrenRouteActive: currentRoute.matched.some((route) => route.path === `${RoutePath.ABOUT}/:id?`),
    },
    notAuthorized: {
      name: translateKey('routes.notAuthorized'),
      path: RoutePath.NOT_AUTHORIZED,
      isActive: currentRoute.matched.some((route) => route.path === RoutePath.NOT_AUTHORIZED),
    },
    loggedOut: {
      name: translateKey('routes.loggedOut'),
      path: RoutePath.SIGNED_OUT,
      isActive: currentRoute.matched.some((route) => route.path === RoutePath.SIGNED_OUT),
    },
  }));
};
