import { NodeEnv } from './types/node-env.enum';

export type Auth0Vue = typeof import('node_modules/@auth0/auth0-vue/dist/typings/index');

let auth0VueInstance: Auth0Vue | null = null;

export async function getAuth0Vue(): Promise<Auth0Vue | null> {
  if (!auth0VueInstance && process.env.NODE_ENV !== NodeEnv.TESTING) {
    auth0VueInstance = await import('@auth0/auth0-vue');
  }

  return auth0VueInstance;
}
