import { NodeEnv } from '@/types/node-env.enum';

export type Config = {
  domain: string;
  clientId: string;
  audience: string;
  apiProductsEndpoint: string;
  apiUserEndpoint: string;
  wsUserEndpoint: string;
};

const getConfig = (): Config => {
  const raw_cfg = document.getElementById('config')?.getAttribute('data-config');
  return raw_cfg ? JSON.parse(raw_cfg) : {};
};

const config = getConfig();

if (process.env.NODE_ENV === NodeEnv.TESTING) {
  config.apiProductsEndpoint = '';
  config.apiUserEndpoint = '';
}

export default config;
